














import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';
import PayoutScheduleForm from '@/components/dashboard/PayoutScheduleForm.vue';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
  findAccount: (id: string) => ApiResource.Business.Account | undefined,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  components: {
    PayoutScheduleForm,
  },
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null as null | Error,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
      findAccount: 'business/FindAccount',
    }),
    BUSINESS(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    PAYOUT_ACCOUNT(): ApiResource.Business.Account | undefined {
      return this.findAccount(this.BUSINESS.id);
    },
    PAYOUT_SETTINGS(): ApiResource.Business.Account['settings']['payouts'] | null {
      if (!this.PAYOUT_ACCOUNT) return null;
      if (!this.PAYOUT_ACCOUNT.settings) return null;
      if (!this.PAYOUT_ACCOUNT.settings.payouts) return null;
      return this.PAYOUT_ACCOUNT.settings.payouts;
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
