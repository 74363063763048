

















































































import Vue from 'vue';
import { ApiResource, SelectOption, Weekday } from '@/types';

const WEEKDAYS: Weekday[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export default Vue.extend({
  props: {
    businessId: {
      type: String,
      required: true,
    },
    payoutSchedule: {
      type: Object as () => ApiResource.Business.Account['settings']['payouts']['schedule'],
      required: true,
    },
  },
  data() {
    return {
      error: null,
      form: {
        interval: this.payoutSchedule.interval,
        monthly_anchor: this.payoutSchedule.monthly_anchor || new Date().getDate(),
        weekly_anchor: this.payoutSchedule.weekly_anchor || WEEKDAYS[new Date().getDay()],
      },
      loading: false,
      validation: {
        interval: null,
        anchor: null,
      },
    };
  },
  computed: {
    INTERVALS(): string[] {
      return ['daily', 'weekly', 'monthly'];
    },
    INTERVAL_OPTIONS(): SelectOption[] {
      const opts: SelectOption[] = [];

      this.INTERVALS.forEach((value) => {
        opts.push({
          value,
          text: this.$t(`interval.${value}`),
        });
      });

      return opts;
    },
    MONTHLY_OPTIONS(): SelectOption[] {
      const opts: SelectOption[] = [];

      [...Array(31).keys()].forEach((i) => {
        opts.push({
          value: i + 1,
          text: this.$t(`month.${i + 1}`),
        });
      });

      return opts;
    },
    WEEKLY_OPTIONS(): SelectOption[] {
      const opts: SelectOption[] = [];

      [...Array(7).keys()].forEach((i) => {
        opts.push({
          value: WEEKDAYS[i],
          text: this.$t(`weekdays.${WEEKDAYS[i]}`),
        });
      });

      return opts;
    },
  },
  methods: {
    async save(): Promise<void> {
      this.error = null;
      this.loading = true;

      let anchor;

      if (this.form.interval === 'monthly') anchor = this.form.monthly_anchor;
      else if (this.form.interval === 'weekly') anchor = this.form.weekly_anchor;

      await this.$store.dispatch('business/UpdatePayoutSchedule', {
        BUSINESS_ID: this.businessId,
        form: {
          interval: this.form.interval,
          anchor,
        },
      }).catch((e) => { this.error = e; });

      this.loading = false;
    },
  },
});
